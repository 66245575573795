import React from "react"
import AboutCard from "../cards/AboutCard"

const AboutUs = () => {
  const infoAbout = [
    {
      name: "Sushrut",
      intro: "Hey! I’m Sushrut, Founder of VCMedia Labs. I work on your LinkedIn-&-X personal branding and client acquisition system set up.",
      twitterUrl: "https://x.com/SushrutKM",
      linkedinUrl: "https://www.linkedin.com/in/sushrutkm/"
    },
    {
      name: "Yashika",
      intro: "Hey! I’m Yashika, Co-Founder of VCMedia Labs. My specialization includes: Outbound link generation and building sales assets & lead magnets.",
      twitterUrl: "https://x.com/yayyashika",
      linkedinUrl: "https://www.linkedin.com/in/yashika-s-15852618b/"
    },
  ]

  return (
    <div id="about-us" className="min-h-screen flex items-center justify-center bg-white">
      <div className="w-full  p-6 sm:p-10 lg:p-16 bg-white mx-auto">
        <div className="flex flex-col md:flex-row justify-center items-center mb-8 space-y-4 md:space-y-0 md:space-x-6">
          <span className="font-anton text-[4em] lg:text-5xl text-[#00185C]">About us</span>
          <span className="w-full md:w-96 text-sm sm:text-base md:text-lg lg:text-xl md:border-l-4 pl-2 border-gray-300 text-[#6E6E6E] font-grotesk text-center mt-4 md:mt-0">
            We at VC Media help business owners build a founder-led sales system within 6 months
          </span>
        </div>

        <div className="flex flex-row flex-wrap justify-center gap-6 sm:gap-8 lg:gap-16">
          {infoAbout.map( (item, index) => (
            <AboutCard key={index} props={item} className="mb-8" />
          ))}
        </div>
      </div>
    </div>
  )
}

export default AboutUs
