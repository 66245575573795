import React from "react";

const TestimonialCard = (props) => {
  return (
    <div className=" flex flex-col h-auto  md:h-[350px] w-80   max-w-xl  border-2 border-black bg-[#F1F5FF] text-[#6E6E6E] rounded-xl  scale-90 p-2 ">
      <div className="flex items-center  p-5 gap-3 h-auto md:h-[72px] justify-start">
        <div className="flex flex-col justify-center items-start   p-4 ">
          <span className="   font-sans md:font-serif text-black text-2xl 	 ">
            {props.name}
          </span>
          <span className="text-black">{props.designation}</span>
        </div>
      </div>

      <div className="p-6 h-auto md:h-[250px] overflow-hidden">
        <ul class="list-none font-[Space Grotesk] font-normal text-lg md:text-lg leading-tight">
          <li>{props.content}</li>
        </ul>
      </div>
    </div>
  );
};

export default TestimonialCard;
