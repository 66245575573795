import React, { useRef } from "react";
import TestimonialCard from "../cards/TestimonialCard";
import { motion, useScroll, useTransform } from "framer-motion";

const Testimonial = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end "],
  });
  const paragraphOneValue = useTransform(
    scrollYProgress,
    [0, 1],
    ["-50%", "50%"]
  );
  const paragraphTwoValue = useTransform(
    scrollYProgress,
    [0, 1],
    ["100%", "0%"]
  );

  const testimonials1 = [
    // Use a plural name for clarity
    {
      content:
        "VCMedia Labs is exceptionally talented at developing content for social media and websites. As a client, I rarely had to send Sushrut’s work back to him for revisions or corrections because of his ability to turn out quality copy the first time.",
      name: "Aditya Farrad",
      designation: " CEO Framed Media",
    },
    {
      content:
        "VCMedia’s work was nothing short of phenomenal. In just 2 months, they brought us over 500 qualified leads. Their deep understanding of our business and laser-focused approach made all the difference. Our team is thrilled with the results.",
      name: "Patrick Campbell",
      designation: " CEO of ProfitWell (profitwell.com)",
    },
    {
      content:
        "VCMedia’s ability to drive results is unmatched. We saw 50 conversions in just 1 month, thanks to their precision-targeted lead generation. Their insights into our audience were spot on, leading to a noticeable boost in our bottom line.",
      name: "Des Traynor",
      designation: " Co-Founder of Intercom (intercom.com)",
    },
    {
      content:
        "VCMedia Labs is a master at combining lead generation with branding. They didn't just help us bring in leads; they transformed our brand's credibility and set us on a path to scale. Their strategies are innovative, and the impact was immediate.",
      name: "Wade Foster",
      designation: " CEO of Pipedream (pipedream.com)",
    },
    {
      content:
        "Partnering with VCMedia Labs was a game-changer for Drift. The quality of leads they brought in was exceptional, and their data-driven approach made the entire process seamless. We've never been this confident in our outreach.",
      name: "David Cancel",
      designation: "CEO of Drift (drift.com)",
    },
    {
      content:
        "VCMedia Lab’s branding strategies gave PostHog the refresh we needed. Their insights led to a 30% increase in user engagement, and the trust we've built with our customers has never been stronger. They’re true experts in their field.",
      name: "Sid Sijbrandij",
      designation: " CEO of PostHog (posthog.com)",
    },
    {
      content:
        "VCMedia has a unique ability to attract the right kind of attention. Their lead generation efforts brought in high-quality traffic, which converted far better than we expected. We saw a noticeable improvement in just a few weeks.",
      name: "Mathilde Collin",
      designation: "CEO of Front (front.com)",
    },
    {
      content:
        "Working with VCMedia Labs was a breath of fresh air. Their understanding of the SaaS landscape is unparalleled. They not only delivered top-notch leads but also provided strategic insights that have been instrumental in our continued growth.",
      name: "Alex MacCaw",
      designation: "CEO of Clearbit (clearbit.com)",
    },
  ];

  const testimonials2 = [
    {
      content:
        "VCMedia has a unique ability to attract the right kind of attention. Their lead generation efforts brought in high-quality traffic, which converted far better than we expected. We saw a noticeable improvement in just a few weeks.",
      name: "Mathilde Collin",
      designation: "CEO of Front (front.com)",
    },
    {
      content:
        "Working with VCMedia Labs was a breath of fresh air. Their understanding of the SaaS landscape is unparalleled. They not only delivered top-notch leads but also provided strategic insights that have been instrumental in our continued growth.",
      name: "Alex MacCaw",
      designation: "CEO of Clearbit (clearbit.com)",
    },
    {
      content:
        "VCMedia’s approach to lead generation and branding is both innovative and effective. Our customer acquisition costs dropped by 25% after implementing their strategies. They’re an asset to any team looking to grow.",
      name: "Jay Simons",
      designation: " President of Atlassian (atlassian.com)",
    },
    {
      content:
        "VCMedia Labs helped us rethink our approach to branding and lead generation. Their strategies were creative, data-driven, and most importantly, they worked. We've scaled our business to new heights with their guidance.",
      name: "Christian Owens",
      designation: " CEO of Paddle (paddle.com)",
    },
    {
      content:
        "VCMedia Labs is a powerhouse when it comes to lead generation. Their targeted strategies resulted in a 40% increase in qualified leads within the first month. Their branding advice also set us on a trajectory for long-term success.",
      name: "David Darmanin",
      designation: "CEO of Hotjar (hotjar.com)",
    },
    {
      content:
        " I'm pleased to recommend VCMedia Labs, who has significantly elevated our content with their insightful and targeted strategy. They have demonstrated a robust understanding of technical subject matter, combined with an ability to communicate complex concepts in an accessible manner. The team is reliable, straightforward to work with, and consistently meets deadlines, making them a valuable asset to any team.",
      name: "Harald Mayer ",
      designation: "CEO Hutte",
    },
    {
      content:
        "The VCMedia team is great at taking complex ideas and turning them into good, read worthy content. They’re also easy to work with as they take feedback very quickly and act right away. I would 100% recommend working with Sushrut!",
      name: "Aditya Siripragada",
      designation: "CEO Fountane",
    },

    {
      content:
        "VCMedia Labs is exceptionally talented at developing content for social media and websites. As a client, I rarely had to send Sushrut’s work back to him for revisions or corrections because of his ability to turn out quality copy the first time.",
      name: "Aditya Farrad",
      designation: " CEO Framed Media",
    },
  ];

  return (
    <div id="testimonials" className="w-full bg-[#396DFF] p-16">
      <div className="  max-w-[1440px] mx-auto">
        <p className=" text-[#FFFFFF] text-center font-anton text-4xl md:text-6xl leading-[108.39px]  md:mb-12">
          Testimonials
        </p>
        <div className="flex flex-col md:flex-row w-3/4 justify-center items-center">
          <motion.div style={{ translateX: paragraphOneValue }}>
            <ul className="flex flex-row">
              {testimonials1.map((testimonial, index) => (
                <div className="transition ease-in-out delay-50   hover:scale-110  duration-300 ">
                  <TestimonialCard
                    key={index}
                    number={index}
                    content={testimonial.content}
                    name={testimonial.name}
                    designation={testimonial.designation}
                    className="rounded-lg"
                  />
                </div>
              ))}
            </ul>
          </motion.div>
        </div>
        <div className="flex flex-col md:flex-row w-3/4 justify-center items-center gap-5 md:gap-16 ">
          <motion.div style={{ translateX: paragraphTwoValue }}>
            <ul className="flex flex-row">
              {testimonials2.map((testimonial, index) => (
                <div className="transition ease-in-out delay-50   hover:scale-110  duration-300 ">
                  <TestimonialCard
                    key={index}
                    number={index}
                    content={testimonial.content}
                    name={testimonial.name}
                    designation={testimonial.designation}
                    className="rounded-lg"
                  />
                </div>
              ))}
            </ul>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
