

export const Image = [
    {
         src: 'Swiggy.png',
         alt: "swiggy"
    },
    {
        src: "typeform.png",
        alt: "typeform"
    },
    {
         src: "scension.png",
         alt: "scension"
    },
    {
         src: "salee_ai.png",
         alt: "salee_ai"
    },
    {
         src: "hutte.png",
         alt: "hutte"
    },
    {
         src: "gtm.png",
         alt: "gtm"
    },
    {
         src: "revsolutions.png",
         alt: "revsolutions"
    },
    {
         src: "fountane.png",
         alt: "fountane"
    },
    {
         src: "fueled.png",
         alt: "fueled"
    }
];
