import firstVideo from "../videos/firstVideo.mp4";
import secondVideo from "../videos/secondVideo.mp4";

export const info = [
  {
    heading: "Personal Branding Workflow",
    subheading:
      "We follow a bit of AI, human-copywriting skills, and a 3-pillar system to create authority content on scale.",
    video: firstVideo,
  },
  {
    heading: "Lead Generation Workflow",
    subheading:
      "We use AI and other scraping tools to find intent-based leads for a better response rate.",
    video: secondVideo,
  },
];
