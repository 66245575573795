import React, { useEffect, useState } from "react";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [header, setHeader] = useState(false);

  const scrollHeader = () => {
    if (window.scrollY >= 20) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHeader);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setIsOpen(false); // Close the menu after clicking on a link
  };

  return (
    <div
      className={
        header
          ? "fixed bg-[#396DFF] z-50 border-b border-none w-full flex flex-row justify-between lg:justify-around md:justify-around sm:justify-between  text-white px-6 py-4"
          : "fixed  bg-[#396DFF] z-50 border-b border-none w-full flex flex-row justify-between lg:justify-around md:justify-around sm:justify-between  text-white px-6 py-4"
      }
    >
      <span
        className="font-anton text-[24px] cursor-pointer"
        onClick={() => scrollToSection("home")}
      >
        VCMEDIA LABS
      </span>

      {/* Logo and Hamburger Menu */}
      <div className="flex items-center justify-center">
        {/* Menu Links */}
        <ul
          className={`text-grotesk text-[16px] flex flex-col md:flex-row gap-4 md:gap-10 ${
            isOpen ? "flex" : "hidden"
          } md:flex`}
        >
          <li
            className="cursor-pointer hover:text-gray-200"
            onClick={() => scrollToSection("what-we-do")}
          >
            What do we do
          </li>
          <li
            className="cursor-pointer hover:text-gray-200"
            onClick={() => scrollToSection("why-us")}
          >
            Why us
          </li>
          <li
            className="cursor-pointer hover:text-gray-200"
            onClick={() => scrollToSection("testimonials")}
          >
            Testimonials
          </li>
          <li
            className="cursor-pointer hover:text-gray-200"
            onClick={() => scrollToSection("about-us")}
          >
            About us
          </li>
        </ul>
      </div>

      <a target="_blank" href="https://calendly.com/vcmedialabs/discovery-call"
        className="hidden md:block cursor-pointer hover:bg-[#636262] hover:text-white bg-white text-black font-bricol text-[13px] font-bold px-[24px] py-[8px] rounded-lg mt-4 md:mt-0"
      >
        Book a call
      </a>

      {/* Hamburger Menu Icon */}
      <div className="block md:hidden">
        <button
          onClick={toggleMenu}
          className="text-white focus:outline-none focus:bg-[#1a365d]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Navigation;
